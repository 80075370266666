.changePasswordModalFrame {
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  z-index: 10000;
}

.changePasswordModal {
  position: absolute;
  width: 450px;
  height: 450px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0, 0);
  animation: changePasswordPopInAnimation .3s ease-out forwards;
}

.changePasswordModal__buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@keyframes changePasswordPopInAnimation {
  0% {
    transform: translate(-50%, -50%) scale(0, 0);
    opacity: 0;
  }
  80% {
    transform: translate(-50%, -50%) scale(1.05, 1.05);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
  }
}